import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../../components/floating-button/floating-button';
import Layout from '../../../../components/layout';
import React from 'react';

const DigitalStudentCredentialsPage = (props) => {
    const components = props.data.cms?.digitalStudentsCredentialsPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default DigitalStudentCredentialsPage;

export const query = graphql`
query ($locale: String!){
  cms {
    digitalStudentsCredentialsPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          backgroundImage {
            url
          }
          backgroundImageHeight
          contentOffset
          mobileContentOffset
          primaryImage {
            url
          }
          headerSubtitle: subtitle
          textBoxCustomClasses
          title
        }
        ... on STRAPI_ComponentComponentsBenefits {
          __typename
          topPusherHeightMobile
          imagesTailwindClasses
          topPusherHeight
          itemsToLeft
          sectionTitle {
            tailwindClasses
            ytLink
            style
            title
            description
          }
          benefitsScrollColor
          hasDarkBackground
          Benefit {
            subtitle
            title
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          tabHeight
          tabs {
            type
            imagesTailwindClasses
            title
            subtitle
            scrollColor
            labelImage {
              url
            }
            labelColour
            label
            isLabelImageVisibleOnDesktop
            imagesMetadata
            images {
              url
            }
          }
          topPusherHeight
          topPusherHeightMobile
          tabsDarkBackground
        }
        ... on STRAPI_ComponentComponentsOffsetImageWithList {
          __typename
          ImageWithText {
            content
            image {
              url
            }
          }
          image {
            url
          }
          imageHeight
          isImageVisibleOnMobile
          imageOffset
          imageType
          mobileImageHeight
          offsetImageSubtitle: subtitle
          title
          topPusherHeight
          topPusherHeightMobile
          setComponentPadding
          isReverted
        }
        ... on STRAPI_ComponentComponentsSingleImage {
          __typename
          image {
            url
          }
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentUseCasesUseCasesSection {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          UseCasesColumns {
            title
            subtitle
            icon {
              url
            }
            backgroundImage {
              url
            }
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}

`;
